import hoistNonReactStatics from "hoist-non-react-statics"
import { SSRConfig, UserConfig, useTranslation } from "next-i18next"
import type { AppProps as NextJsAppProps } from "next/app"
import { useEffect } from "react"
import { loadDayJSLocale } from "../../utils/localization/loadDayJSLocale"
import useForceUpdate from "../../utils/useForceUpdate"
import { WithYupLocalization } from "./YupLocalization"

type AppProps = NextJsAppProps & {
  pageProps: SSRConfig
}

export const appWithTranslations = <Props extends AppProps = AppProps>(
  WrappedComponent: React.ComponentType<Props>,
  configOverride: UserConfig | null = null
) => {
  const AppWithTranslation = (props: Props) => {
    const { i18n } = useTranslation()
    const forceUpdate = useForceUpdate()

    useEffect(() => {
      const update = async () => {
        await loadDayJSLocale(i18n.language)
        forceUpdate()
      }
      update().catch(console.error)
    }, [i18n.language])

    return (
      <WithYupLocalization>
        <WrappedComponent {...props} />
      </WithYupLocalization>
    )
  }

  return hoistNonReactStatics(AppWithTranslation, WrappedComponent)
}
