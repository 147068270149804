import { useTranslation } from "next-i18next"
import { FunctionComponent } from "react"
import { setLocale } from "yup"
import {
  ArrayLocale,
  BooleanLocale,
  DateLocale,
  MixedLocale,
  NumberLocale,
  ObjectLocale,
  StringLocale,
} from "yup/lib/locale"
import printValue from "yup/lib/util/printValue"

export const useYupLocalization = () => {
  const { t } = useTranslation("common")

  const mixedObject: Required<MixedLocale> = {
    default: (props): string =>
      t("validation.yup.mixed.default", "{{path}} is invalid", props),
    required: (props): string =>
      t("validation.yup.mixed.required", "{{path}} is a required field", props),
    oneOf: (props): string =>
      t(
        "validation.yup.mixed.oneOf",
        "{{path}} must be one of the following values: {{values}}",
        props
      ),
    notOneOf: (props): string =>
      t(
        "validation.yup.mixed.notOneOf",
        "{{path}} must not be one of the following values: {{values}}",
        props
      ),
    notType: (props): string => {
      let isCast =
        props.originalValue != null && props.originalValue !== props.value
      let msg = t(
        "validation.yup.mixed.notType.msg",
        "{{path}} must be a `{{type}}` type, but the final value was: `{{value}}`.",
        {
          path: props.path,
          type: props.type,
          value: printValue(props.value, true),
        }
      )

      if (isCast) {
        msg += t(
          "validation.yup.mixed.notType.isCast",
          "(cast from the value `{{originalValue}}`).",
          { originalValue: printValue(props.originalValue, true) }
        )
      }

      if (props.value === null) {
        msg += t(
          "validation.yup.mixed.notType.nullable",
          `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``
        )
      }

      return msg
    },
    defined: (props): string =>
      t("validation.yup.mixed.defined", "{{path}} must be defined", props),
  }

  const stringObject: Required<StringLocale> = {
    length: (props): string =>
      t(
        "validation.yup.string.length",
        "{{path}} must be exactly {{length}} characters",
        props
      ),
    min: (props): string =>
      t(
        "validation.yup.string.min",
        "{{path}} must be at least {{min}} characters",
        props
      ),
    max: (props): string =>
      t(
        "validation.yup.string.max",
        "{{path}} must be at most {{max}} characters",
        props
      ),
    matches: (props): string =>
      t(
        "validation.yup.string.matches",
        '{{path}} must match the following: "{{regex}}"',
        props
      ),
    email: (props): string =>
      t("validation.yup.string.email", "{{path}} must be a valid email", props),
    url: (props): string =>
      t("validation.yup.string.url", "{{path}} must be a valid URL", props),
    uuid: (props): string =>
      t("validation.yup.string.uuid", "{{path}} must be a valid UUID", props),
    trim: (props): string =>
      t(
        "validation.yup.string.trim",
        "{{path}} must be a trimmed string",
        props
      ),
    lowercase: (props): string =>
      t(
        "validation.yup.string.lowercase",
        "{{path}} must be a lowercase string",
        props
      ),
    uppercase: (props): string =>
      t(
        "validation.yup.string.uppercase",
        "{{path}} must be a upper case string",
        props
      ),
  }

  const numberObject: Required<NumberLocale> = {
    min: (props): string =>
      t(
        "validation.yup.number.min",
        "{{path}} must be greater than or equal to {{min}}",
        props
      ),
    max: (props): string =>
      t(
        "validation.yup.number.max",
        "{{path}} must be less than or equal to {{max}}",
        props
      ),
    lessThan: (props): string =>
      t(
        "validation.yup.number.lessThan",
        "{{path}} must be less than {{less}}",
        props
      ),
    moreThan: (props): string =>
      t(
        "validation.yup.number.moreThan",
        "{{path}} must be greater than {{more}}",
        props
      ),
    positive: (props): string =>
      t(
        "validation.yup.number.positive",
        "{{path}} must be a positive number",
        props
      ),
    negative: (props): string =>
      t(
        "validation.yup.number.negative",
        "{{path}} must be a negative number",
        props
      ),
    integer: (props): string =>
      t("validation.yup.number.integer", "{{path}} must be an integer", props),
  }

  const dateObject: Required<DateLocale> = {
    min: (props): string =>
      t(
        "validation.yup.date.min",
        "{{path}} field must be later than {{min}}",
        props
      ),
    max: (props): string =>
      t(
        "validation.yup.date.max",
        "{{path}} field must be at earlier than {{max}}",
        props
      ),
  }

  const booleanObject: Required<BooleanLocale> = {
    isValue: (props): string =>
      t(
        "validation.yup.boolean.isValue",
        "{{path}} field must be {{value}}",
        props
      ),
  }

  const objectObject: Required<ObjectLocale> = {
    noUnknown: (props): string =>
      t(
        "validation.yup.object.noUnknown",
        "{{path}} field has unspecified keys: {{unknown}}",
        props
      ),
  }

  const arrayObject: Required<ArrayLocale> = {
    min: (props): string =>
      t(
        "validation.yup.array.min",
        "{{path}} field must have at least {{min}} items",
        props
      ),
    max: (props): string =>
      t(
        "validation.yup.array.max",
        "{{path}} field must have less than or equal to {{max}} items",
        props
      ),
    length: (props): string =>
      t(
        "validation.yup.array.length",
        "{{path}} must have {{length}} items",
        props
      ),
  }

  setLocale({
    mixed: mixedObject,
    string: stringObject,
    number: numberObject,
    date: dateObject,
    object: objectObject,
    array: arrayObject,
    boolean: booleanObject,
  })
}

export const WithYupLocalization: FunctionComponent = ({ children }) => {
  useYupLocalization()
  return <>{children}</>
}
