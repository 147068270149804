import { CssBaseline } from "@material-ui/core"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import duration from "dayjs/plugin/duration"
import isBetween from "dayjs/plugin/isBetween"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { AnimatePresence, motion } from "framer-motion"
import { appWithTranslation } from "next-i18next"
import { DefaultSeo } from "next-seo"
import Head from "next/head"
import React from "react"
import { FirebaseAppProvider } from "reactfire"
import "swiper/components/effect-coverflow/effect-coverflow.scss"
import "swiper/swiper.scss"
import "video.js/dist/video-js.css"
import { appWithTranslations as appWithTranslations2 } from "../components/localization/appWithLocalization"
import { firebaseConfig } from "../config/firebase"
import { seoDefaults } from "../config/seo-defaults"
import { Metric } from "../types/Metric"
import { FirebaseComponents } from "../utils/FirebaseComponents"
import { reportMetric } from "../utils/google-analytics"

dayjs.extend(isBetween)
dayjs.extend(advancedFormat)
dayjs.extend(duration)
dayjs.extend(localizedFormat)

export function reportWebVitals(metric: Metric) {
  reportMetric(metric)
}

const variants = {
  hidden: { opacity: 0, x: 0, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: 0 },
}

function StrealOnlineApp({ Component, pageProps, router }: any) {
  return (
    <React.Fragment>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <CssBaseline />
      <style global jsx>
        {`
          body {
            min-width: 360px;
          }

          * {
            box-sizing: border-box;
            line-height: 2;
          }

          p {
            font-size: 1rem;
          }

          .grecaptcha-badge {
            visibility: hidden;
          }
        `}
      </style>

      <DefaultSeo {...seoDefaults} />
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <FirebaseComponents>
          <AnimatePresence
            exitBeforeEnter
            initial={false}
            onExitComplete={() => window.scrollTo(0, 0)}
          >
            <motion.div
              key={router.route}
              initial="hidden"
              animate="enter"
              exit="exit"
              transition={{ type: "linear" }}
              variants={variants}
            >
              <Component {...pageProps} />
            </motion.div>
          </AnimatePresence>
        </FirebaseComponents>
      </FirebaseAppProvider>
    </React.Fragment>
  )
}

export default appWithTranslation(appWithTranslations2(StrealOnlineApp))
