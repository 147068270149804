export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

export const loginMethodsConfig = {
  email: process.env.NEXT_PUBLIC_LOGIN_EMAIL === "true",
  google: process.env.NEXT_PUBLIC_LOGIN_GOOGLE === "true",
  facebook: process.env.NEXT_PUBLIC_LOGIN_FACEBOOK === "true",
  sso: process.env.NEXT_PUBLIC_LOGIN_SSO === "true",
  thirdParty: function () {
    return this.google || this.facebook || this.sso
  },
}
