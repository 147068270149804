import { DefaultSeoProps } from "next-seo"
import { useFacebookAppId } from "../utils/useFacebookAppId"
import { useWebsiteName } from "../utils/useWebsiteName"

export const seoDefaults: DefaultSeoProps = {
  openGraph: {
    type: "website",
    locale: "en",
    site_name: useWebsiteName()!,
    title: `${useWebsiteName()!} - Streaming content everywhere`,
  },
  twitter: {
    cardType: "summary_large_image",
  },
  facebook: {
    appId: useFacebookAppId()!,
  },
}
