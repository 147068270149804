import ReactGA from "react-ga"
import { Metric } from "../types/Metric"

export const initGA = () => {
  ReactGA.initialize("UA-168660770-2")
}

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
}

export const reportMetric = ({ id, name, label, value }: Metric) => {
  ReactGA.event({
    category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    action: name,
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  })
}

export const logEvent = (category = "", action = "") => {
  if (category && action) {
    ReactGA.event({ category, action })
  }
}

export const logException = (description = "", fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal })
  }
}
